<!--
 * @ Author: Clark
 * @ Create Time: 2023-02-05 20:20:57
 * @ Modified by: Your name
 * @ Modified time: 2023-03-03 13:36:25
 * @ Description: 司机管理>充电客户>客户机构列表 
 -->

<template>
  <scroll-layout>
    <template slot="header">
      <ykc-search
        ref="YkcSearch"
        :data="searchData"
        :searchForm="searchParams"
        @click="handleSearchConfirm"
        @clean="handleSearchClean"></ykc-search>
    </template>
    <div class="motorcade-main-account">
      <ykc-table
        ref="YkcTable"
        operateFixedType="right"
        :data="tableData"
        :title="tableTitle"
        :columns="tableColumns"
        :rowSelectable="row => ['1', '2'].includes(row.status)"
        :operateButtons="tableOperateButtons.filter(item => item != null)">
        <template #headerButtons>
          <div class="table-header-button">
            <template v-for="(btn, i) in pageButtons">
              <ykc-button
                :type="btn.type"
                v-if="i != 1 && btn.enabled()"
                :key="i"
                @click="btn.handleClick">
                {{ btn.text }}
              </ykc-button>
              <el-dropdown @command="handleCommand" v-if="i == 1 && btn.enabled()" :key="i">
                <ykc-button :type="btn.type" v-if="btn.enabled()">
                  {{ btn.text }}
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </ykc-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    :command="beforeHandleCommand('addMain')"
                    v-if="code('customer:org:mainorg:add')">
                    主机构
                  </el-dropdown-item>
                  <el-dropdown-item
                    :command="beforeHandleCommand('addChild')"
                    v-if="code('customer:org:childorg:add')">
                    子机构
                  </el-dropdown-item>
                  <el-dropdown-item
                    :command="beforeHandleCommand('addFlow')"
                    v-if="code('customer:org:flow:add')">
                    流量平台
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </div>
        </template>
        <ykc-pagination
          slot="pagination"
          ref="YkcPagination"
          :total="pageInfo.total"
          :page-size.sync="pageInfo.size"
          :current-page.sync="pageInfo.current"
          @size-change="handlePaginationSizeChange"
          @current-change="handlePaginationCurrentChange"></ykc-pagination>
      </ykc-table>
    </div>
    <ykc-drawer
      :show.sync="showDrawer"
      :title="drawerTitle"
      :on-close="onAddDrawerClose"
      :before-cancel="onAddDrawerCancel"
      ensure-txt="保存">
      <template #footer>
        <div class="drawer-button-wrap">
          <template v-for="(btn, i) in drawerButtons">
            <ykc-button :type="btn.type" v-if="btn.enabled()" :key="i" @click="btn.handler">
              {{ btn.label }}
            </ykc-button>
          </template>
        </div>
      </template>
      <div class="drawer-main-body" v-if="showDrawer">
        <ImportData ref="addOrEdit" v-if="drawerType === 'import'"></ImportData>
        <AddOrEdit
          v-if="drawerType === 'edit'"
          :active="stepsIndex"
          ref="addOrEdit"
          :isEdit="isEdit"
          :id="id"
          :isAddChild="isAddChild"></AddOrEdit>
      </div>
    </ykc-drawer>
  </scroll-layout>
</template>

<script>
  import AddOrEdit from './AddOrEdit.vue';
  import ImportData from './ImportData.vue';
  import { offlineExport, code } from '@/utils';
  import { customerOrg } from '@/service/apis';
  import YkcDialog from '@/components/ykc-ui/dialog/index';

  export default {
    name: 'MotorcadeMainAccountList',
    components: {
      AddOrEdit,
      ImportData,
    },
    data() {
      return {
        id: '',
        drawerType: '',
        stepsIndex: 0,
        mainOrgList: [],
        isEdit: false,
        showDrawer: false,
        drawerButtons: [
          {
            label: '取消',
            type: 'plain',
            enabled: () => true,
            handler: () => {
              YkcDialog({
                dialogType: 'feedback',
                showTitle: false,
                showFooter: true,
                desc: '取消后操作将不被保存，确认是否取消？',
                onCancel: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                },
                onConfirm: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                  this.showDrawer = false; // 隐藏抽屉
                },
              });
            },
          },
          {
            label: '保存',
            enabled: () => true,
            handler: () => {
              this.$refs.addOrEdit.submitForm().then(() => {
                this.stepsIndex = 0;
                this.showDrawer = false;
                this.searchTableList();
              });
            },
          },
        ],
        pageButtons: [
          {
            id: '0',
            text: '批量新增',
            type: 'plain',
            enabled: () => this.code('customer:org:batch'),
            handleClick: () => {
              this.doImportFile();
            },
          },
          {
            id: '1',
            text: '新增',
            type: 'plain',
            enabled: () =>
              this.code('customer:org:mainorg:add') ||
              this.code('customer:org:childorg:add') ||
              this.code('customer:org:flow:add'),
            handleClick: () => {
              this.add();
            },
          },
          {
            id: '2',
            text: '导出',
            type: 'plain',
            enabled: () => this.code('customer:org:export'),
            handleClick: () => {
              this.doExportFile();
            },
          },
        ],
        tableData: [],
        tableTitle: '机构列表',
        tableColumns: [
          { label: '机构编码', prop: 'id', minWidth: '150px' },
          { label: '机构名称', prop: 'name', minWidth: '150px' },
          { label: '机构类型', prop: 'orgType', minWidth: '100px' },
          { label: '统一社会信用代码', prop: 'socialCreditCode', minWidth: '100px' },
          { label: '链接性质', prop: 'linkNature', minWidth: '100px' },
          { label: '主机构', prop: 'parentName', minWidth: '100px' },
          { label: '联系人', prop: 'manageName', minWidth: '100px' },
          { label: '联系方式', prop: 'contactInfo', minWidth: '100px' },
        ],
        tableOperateButtons: [
          {
            render: (h, data, { row }) =>
              this.code('customer:org:edit') ? (
                <ykc-button
                  type="text"
                  onClick={() => {
                    this.isEdit = true;
                    this.id = row.id;
                    this.drawerType = 'edit';
                    this.showDrawer = true;
                    this.isAddChild = row.orgType === '子机构';
                  }}>
                  编辑
                </ykc-button>
              ) : null,
          },
          {
            render: (h, data, { row }) =>
              this.code('customer:org:finance') ? (
                <ykc-button
                  type="text"
                  onClick={() => {
                    this.toFinance(row);
                  }}>
                  财务
                </ykc-button>
              ) : null,
          },
        ],
        pageInfo: {
          current: 0,
          size: 10,
        },
        searchParams: {
          orgMold: '1',
          name: '',
          socialCreditCode: '',
          mainOrgId: '',
          manageName: '',
          contactInfo: '',
        },
      };
    },
    created() {
      this.searchTableList();
      this.queryCompanyMainOrgList();
    },
    computed: {
      drawerTitle() {
        if (this.drawerType === 'import') {
          return '批量导入';
        }
        if (this.isAddChild && !this.isEdit) {
          return '新增子机构';
        }
        return ` ${this.isEdit ? '编辑' : '新增'}机构`;
      },
      searchData() {
        return [
          {
            comName: 'YkcInput',
            key: 'name',
            label: '机构名称',
            placeholder: '请输入机构名称',
          },
          {
            comName: 'YkcInput',
            key: 'socialCreditCode',
            label: '统一社会信用代码',
            placeholder: '请输入统一社会信用代码',
          },
          {
            comName: 'YkcDropdown',
            key: 'mainOrgId',
            label: '主机构',
            placeholder: '请输入主机构',
            data: this.mainOrgList,
            remote: true,
          },
          {
            comName: 'YkcInput',
            key: 'manageName',
            label: '联系人',
            placeholder: '请输入联系人',
          },
          {
            comName: 'YkcInput',
            key: 'contactInfo',
            label: '联系方式',
            placeholder: '请输入联系方式',
          },
        ];
      },
    },
    methods: {
      code,
      /**
       * 动态设置Dropdown的command
       */
      beforeHandleCommand(flag) {
        // flag为传递的参数
        return {
          flag,
        };
      },
      detailLink(row) {
        this.$router.push({
          path: '/marketingCenter/labelUsers/details',
          query: {
            id: row.id,
          },
        });
      },
      toFinance(row) {
        this.$router.push({
          path: '/financialManagement/customerAccount/customerAccountsDetail',
          query: {
            id: row.id,
          },
        });
      },
      jumpTOFlow() {
        this.$router.push({
          path: '/customerCenter/customerUnion/customerUnion/customerUnionList',
          query: {
            type: 'jump',
          },
        });
      },
      add() {
        console.log(this.isAddChild);
        this.showAddDrawer = true;
        this.isEdit = false;
        this.id = '';
        this.drawerType = 'edit';
        this.showDrawer = true;
      },
      handleCommand(e) {
        if (e.flag === 'addFlow') {
          this.jumpTOFlow();
          return;
        }
        this.isAddChild = e.flag === 'addChild';
        this.add();
      },
      handleSpecialCountClick(row) {
        const routeUrl = this.$router.resolve({
          path: '/priceConfig/specialPurchasePrice',
          query: {
            stationId: row.stationId,
          },
        });
        window.open(routeUrl.href, '_blank');
      },
      changeStateSearch() {
        this.pageInfo.current = 0;
        this.searchTableList();
      },
      /** 根据条件查询数据 */
      searchTableList() {
        Promise.all([
          customerOrg.list({
            ...this.pageInfo,
            ...this.searchParams,
            orgMold: '1',
          }),
        ]).then(([res1]) => {
          this.tableData = res1.records;
          this.pageInfo.total = res1.total || 0;
        });
      },
      // 获取机构下拉数据
      queryCompanyMainOrgList() {
        customerOrg.queryMainOrgList({}).then(res => {
          this.mainOrgList = res;
        });
      },
      /**
       * @desc 导出文件 - 车队主账号列表
       * */
      doExportFile() {
        console.log('doExport');
        offlineExport(
          {
            downloadType: 'org',
            jsonNode: {
              ...this.searchParams,
            },
          },
          this.pageInfo.total
        );
      },
      /** 批量导入文件 */
      doImportFile() {
        this.drawerType = 'import';
        this.isEdit = true;
        this.showDrawer = true;
      },
      onAddDrawerClose(done) {
        this.stepsIndex = 0;
        done();
      },
      /**
       * @desc 点击抽屉取消按钮 callback
       * */
      onAddDrawerCancel(drawerDone) {
        YkcDialog({
          dialogType: 'feedback',
          showTitle: false,
          showFooter: true,
          desc: '取消后操作将不被保存，确认是否取消？',
          onCancel: done => {
            done();
          },
          onConfirm: done => {
            done();
            drawerDone();
            if (this.isEdit) {
              this.isEdit = false;
            }
          },
        });
      },
      /**
       * @desc 点击页面左上角按钮 callback
       * */
      handlePageButtonClick(button, event) {
        if (typeof button.handleClick === 'function') {
          button.handleClick(button, event);
        }
      },
      /**
       * @desc 点击查询按钮
       * */
      handleSearchConfirm(form) {
        this.pageInfo.current = 0;
        Object.assign(this.searchParams, form);
        this.searchTableList();
      },
      /**
       * 点击清空查询按钮
       * */
      handleSearchClean() {
        this.searchParams = {
          orgMold: '1',
          name: '',
          socialCreditCode: '',
          mainOrgId: '',
          manageName: '',
          contactInfo: '',
        };
      },
      /**
       * @desc 分页 change 事件监听器
       * */
      handlePaginationCurrentChange(current) {
        this.pageInfo.current = current;
        this.searchTableList();
      },
      /**
       * 分页 size  change  事件监听器
       * */
      handlePaginationSizeChange(size) {
        this.pageInfo.current = 1;
        this.pageInfo.size = size;
        this.searchTableList();
      },
      /**
       * 重置 form data
       * */
      resetFormData() {
        this.ruleForm = {
          orgMold: '1',
        };
      },
    },
  };
</script>
<style lang="scss" scoped>
  .link {
    color: blue;
    cursor: pointer;
  }
  .el-radio-group .item {
    border: 0;
  }
</style>
