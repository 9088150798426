<!--
 * @ Author: Clark
 * @ Create Time: 2023-02-05 20:20:57
 * @ Modified by: Your name
 * @ Modified time: 2023-03-02 10:59:19
 * @ Description: 用户中心>充电客户>客户机构>机构编辑
 -->

<template>
  <!-- 表单样式  如果有温馨提示才加  with-warm-tip， 没有则不加 -->
  <ykc-form :model="ruleForm" :rules="rules" ref="ruleForm" class="el-form-wrap">
    <div style="padding-top: 20px">
      <div v-if="active === 0">
        <ykc-form-item :label="isAddChild ? '子机构名称' : '主机构名称'" prop="name">
          <ykc-input
            v-model="ruleForm.name"
            :placeholder="'中文、英文、数字（1~30字数），不可重复'"></ykc-input>
        </ykc-form-item>
        <ykc-form-item :label="'上级机构'" prop="parentId" v-if="isAddChild">
          <ykc-dropdown
            :disabled="isEdit"
            :remote="true"
            v-model="ruleForm.parentId"
            placeholder="请输入机构关键字"
            :isObject="true"
            :configSet="configSet"
            :searchMaxLength="1000"
            @change="handleSelectMainOrg"
            :data="mainOrgList || []"></ykc-dropdown>
        </ykc-form-item>
        <template v-if="!isAddChild">
          <ykc-form-item label="公司名称" prop="companyName">
            <ykc-input v-model="ruleForm.companyName" :placeholder="'请输入公司名称'"></ykc-input>
          </ykc-form-item>
          <ykc-form-item
            class="img-error"
            label="统一社会信用代码"
            prop="socialCreditCode"
            bottomTip="温馨提示：请认真核对营业执照上的18位编码">
            <ykc-input
              v-model="ruleForm.socialCreditCode"
              maxlength="18"
              :placeholder="'请输入统一社会信用代码'"></ykc-input>
          </ykc-form-item>
        </template>
        <ykc-form-item label="管理员" prop="manageName">
          <ykc-input
            v-model="ruleForm.manageName"
            :placeholder="'请输入机构管理员姓名'"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="联系方式" prop="contactInfo">
          <ykc-input
            v-model="ruleForm.contactInfo"
            maxlength="11"
            :placeholder="'请输入机构管理员联系方式'"></ykc-input>
        </ykc-form-item>
      </div>
    </div>
  </ykc-form>
</template>

<script>
  import regexpObj from '@/utils/regexp';
  import { customerOrg } from '@/service/apis';
  import { getListNameById } from '@/utils/index';

  export default {
    props: {
      active: {
        type: Number,
        default: 0,
      },
      isEdit: {
        type: Boolean,
        default: false,
      },
      id: {
        type: String,
      },
      isAddChild: {
        type: Boolean,
      },
    },
    components: {},
    data() {
      return {
        // 机构下拉框数据显示配置
        configSet: {
          label: 'name',
          value: 'id',
        },
        name: '',
        socialCreditCode: '',

        ruleForm: {},
        mainOrgList: [],
        rules: {
          name: [
            { required: true, message: '中文、英文、数字（1~30字数）,不可重复', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.nameType.regexp,
              message: regexpObj.regexp.input.nameType.errorTips.error('名称'),
            },
            { validator: this.onlyCheckName, trigger: 'blur', message: '名称重复' },
          ],
          parentId: [{ required: true, message: '请选择上级机构', trigger: 'blur' }],
          companyName: [
            { required: true, message: '请输入公司名称', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.nameType.regexp,
              message: regexpObj.regexp.input.nameType.errorTips.error('公司名称'),
            },
          ],
          socialCreditCode: [
            { required: true, message: '请输入正确的18位统一社会信用代码', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.creditCode.regexp,
              message: regexpObj.regexp.input.creditCode.errorTips.error('社会统一信用代码'),
            },
            { validator: this.onlyCheckCreditCode, trigger: 'blur' },
          ],
          manageName: [
            { required: true, message: '请输入机构管理员姓名', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.nameType.regexp,
              message: regexpObj.regexp.input.nameType.errorTips.error('机构管理员姓名'),
            },
          ],
          contactInfo: [
            { required: true, message: '请输入机构管理员联系方式', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.cellphoneType.regexp,
              message: regexpObj.regexp.input.cellphoneType.errorTips.error('机构管理员联系方式'),
            },
          ],
        },
      };
    },
    watch: {
      // eslint-disable-next-line func-names
    },
    computed: {
      formData() {
        const formData = {
          // 机构名称 主机构 公司名称 统一社会信用代码 管理员 联系方式  新增种类
          id: this.ruleForm.id,
          name: this.ruleForm.name,
          parentId: this.ruleForm.parentId,
          mainOrgId: this.ruleForm.mainOrgId,
          mainOrgName: this.ruleForm.mainOrgName,
          companyName: this.ruleForm.companyName,
          socialCreditCode: this.ruleForm.socialCreditCode,
          manageName: this.ruleForm.manageName,
          contactInfo: this.ruleForm.contactInfo,
          orgType: this.isAddChild ? '2' : '1', // orgType 1 主机构 2 子机构
          orgMold: '1', // 客户机构
        };
        return formData;
      },
    },
    created() {
      // 获取机构数据数据
      Promise.all([customerOrg.detail({ id: this.id })]).then(([powerStation]) => {
        this.powerStationList = powerStation;
      });
      this.queryMainOrgWithOutZdlList();
      if (this.isEdit) {
        customerOrg
          .detail({
            id: this.id,
          })
          .then(res => {
            this.ruleForm = {
              orgType: this.isAddChild ? '2' : '1', // 1主机构 2子机构
              orgMold: '',
              ...res,
            };
            if (res.parentId === 0) {
              this.ruleForm.parentId = null;
            }
            this.name = res.name;
            this.socialCreditCode = res.socialCreditCode;
          });
      }
    },
    methods: {
      getListNameById,
      changeStation(val) {
        const item = this.powerStationList.find(o => o.stationId === val);
        this.ruleForm.operatorName = item?.operatorName ?? '';
        this.ruleForm.operatorAttribute = item?.operatorAttribute ?? '';
        this.ruleForm.stationStatusCode = item?.stationStatusCode ?? '';
      },
      // 获取机构下拉数据
      queryMainOrgWithOutZdlList() {
        customerOrg.queryMainOrgWithOutZdlList({}).then(res => {
          this.mainOrgList = res;
        });
      },
      handleSelectMainOrg(e) {
        console.log(e);
        this.ruleForm.mainOrgName = e.name;
        this.ruleForm.mainOrgId = e.mainOrgId;
      },

      /**
       * 名称唯一性校验
       */
      onlyCheckName(rule, value, callback) {
        if (value) {
          customerOrg
            .checkOrgRepeat({ name: value })
            .then(res => {
              if (res && this.ruleForm.name !== this.name) {
                callback(new Error('名称重复'));
              } else {
                callback();
              }
            })
            .catch(() => {});
        }
      },
      /**
       * 统一社会信用代码唯一性校验
       */
      onlyCheckCreditCode(rule, value, callback) {
        if (value) {
          customerOrg
            .checkOrgRepeat({ socialCreditCode: value })
            .then(res => {
              if (res && this.ruleForm.socialCreditCode !== this.socialCreditCode) {
                callback(new Error('该统一社会信用代码已经被占用'));
              } else {
                this.nameValidError = '';
                callback();
              }
            })
            .catch(() => {});
        }
      },
      submitForm() {
        return new Promise(resolve => {
          this.validateForm().then(() => {
            if (this.isEdit) {
              customerOrg.edit(this.formData).then(() => {
                this.$message({
                  message: '保存成功',
                  type: 'success',
                });
                resolve();
              });
            } else {
              customerOrg.add(this.formData).then(() => {
                this.$message({
                  message: '保存成功',
                  type: 'success',
                });
                resolve();
              });
            }
          });
        });
      },
      validateForm() {
        return new Promise((resolve, reject) => {
          this.$refs.ruleForm.validate(valid => {
            if (valid) {
              resolve();
            } else {
              reject();
            }
          });
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  ._line {
    display: flex;
    & > * {
      margin-right: 5px;
    }
  }
</style>
