<!--
 * @Author: Clark
 * @Date: 2023-02-11 19:07:21
 * @LastEditors: Do not edit
 * @LastEditTime: 2023-02-24 19:04:11
 * @FilePath: \ykc-omp-pro\src\views\customerCenter\customerCharge\organization\ImportData.vue
-->
<template>
  <ykc-form :model="ruleForm" :rules="rules" ref="ruleForm" class="el-form-wrap">
    <ykc-form-item
      class="file-error img-error"
      label="选择文件"
      prop="file"
      bottomTip="温馨提示：导入的机构需要保证上级机构已经在列表中存在">
      <ykc-upload
        type="xlsx"
        :size="5"
        ref="file"
        :checkInfo="{ typeMess: '文件格式错误，请重新导入', sizeMess: '文件大小不超过' }"
        @handleRemove="handleRemove"
        @handleChange="fileUpload"
        :temText="linkText"
        :temhref="modelUrl"></ykc-upload>
    </ykc-form-item>
  </ykc-form>
</template>

<script>
  import { filterByIdList } from '@/utils/index';
  import { customerOrg } from '../../../../service/apis';

  export default {
    data() {
      return {
        rules: {
          file: [
            {
              required: true,
              trigger: 'change',
              validator: (rule, value, callback) => {
                if (this.$refs.file.fileList.length > 0) {
                  callback();
                } else {
                  callback(new Error('请选择需要导入的文件'));
                }
              },
            },
          ],
        },
        ruleForm: {
          file: undefined,
        },
        formData: null,
      };
    },
    computed: {
      modelUrl() {
        return `${process.env.VUE_APP_OSS}/resource/%E5%AE%A2%E6%88%B7%E6%9C%BA%E6%9E%84%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx`;
      },
      linkText() {
        return '客户机构导入模板.xlsx';
      },
    },
    created() {},
    methods: {
      filterByIdList,
      fileUpload(file, fileList, isCheck) {
        if (isCheck) {
          this.formData = new FormData();
          const param = {
            file: file.raw,
          };
          Object.entries(param).forEach(([k, v]) => {
            this.formData.append(k, v);
          });
        }
        this.$refs.ruleForm.validateField('file');
      },
      handleRemove() {
        this.ruleForm.file = undefined;
      },
      submitForm() {
        return new Promise(resolve => {
          customerOrg.importOrg(this.formData).then(res => {
            console.log(JSON.stringify(res));
            this.$message({
              message: '批量导入成功',
              type: 'success',
            });
            resolve();
          });
        });
      },
      /** 校验当前表格是否通过 */
      validateForm() {
        return new Promise(resolve => {
          this.$refs.ruleForm.validate(valid => {
            if (valid) {
              resolve();
            }
          });
        });
      },
    },
  };
</script>

<style lang="scss" scoped></style>
